exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-categories-template-categories-template-tsx": () => import("./../../../src/templates/CategoriesTemplate/CategoriesTemplate.tsx" /* webpackChunkName: "component---src-templates-categories-template-categories-template-tsx" */),
  "component---src-templates-category-template-category-template-tsx": () => import("./../../../src/templates/CategoryTemplate/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-category-template-tsx" */),
  "component---src-templates-index-template-index-template-tsx": () => import("./../../../src/templates/IndexTemplate/IndexTemplate.tsx" /* webpackChunkName: "component---src-templates-index-template-index-template-tsx" */),
  "component---src-templates-not-found-template-not-found-template-tsx": () => import("./../../../src/templates/NotFoundTemplate/NotFoundTemplate.tsx" /* webpackChunkName: "component---src-templates-not-found-template-not-found-template-tsx" */),
  "component---src-templates-page-template-page-template-tsx": () => import("./../../../src/templates/PageTemplate/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-page-template-tsx" */),
  "component---src-templates-post-template-post-template-tsx": () => import("./../../../src/templates/PostTemplate/PostTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-post-template-tsx" */)
}

